var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var fp = typeof window !== "undefined" && window.flatpickr !== undefined ? window.flatpickr : {
    l10ns: {}
  };
  var Hungarian = {
    firstDayOfWeek: 1,
    weekdays: {
      shorthand: ["V", "H", "K", "Sz", "Cs", "P", "Szo"],
      longhand: ["Vas\xE1rnap", "H\xE9tf\u0151", "Kedd", "Szerda", "Cs\xFCt\xF6rt\xF6k", "P\xE9ntek", "Szombat"]
    },
    months: {
      shorthand: ["Jan", "Feb", "M\xE1r", "\xC1pr", "M\xE1j", "J\xFAn", "J\xFAl", "Aug", "Szep", "Okt", "Nov", "Dec"],
      longhand: ["Janu\xE1r", "Febru\xE1r", "M\xE1rcius", "\xC1prilis", "M\xE1jus", "J\xFAnius", "J\xFAlius", "Augusztus", "Szeptember", "Okt\xF3ber", "November", "December"]
    },
    ordinal: function () {
      return ".";
    },
    weekAbbreviation: "H\xE9t",
    scrollTitle: "G\xF6rgessen",
    toggleTitle: "Kattintson a v\xE1lt\xE1shoz",
    rangeSeparator: " - ",
    time_24hr: true
  };
  fp.l10ns.hu = Hungarian;
  var hu = fp.l10ns;
  exports.Hungarian = Hungarian;
  exports.default = hu;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const Hungarian = exports.Hungarian,
      __esModule = exports.__esModule;